﻿.sloganSocial {
    
   float: right;
   //margin-top: 20px;
   //margin-bottom: -10px;

    .slogan,
    .sloganTo {
       
    }

    .slogan {
        float: left;
        font-family: museo, serif;
        color: #3a808e;
        text-align:center;
    }

    .sloganTo {
    font-weight: 700;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: -60px;
    font-size: 100%;
    color: #3a808e;
    
    }

    .sloganInspire{
    font-weight: 700;
    font-size: 202%;
    margin-top: -15px;
    color: #3a808e;
    }
    .sloganExcite{
    font-weight: 500;
    font-size: 177%;
    margin-left: 25px;
    margin-top: -25px;
    margin-bottom: 0px;
     color: #3a808e;
    }
    .sloganEducate{
    font-weight: 300;
    font-size: 237%;
    margin-bottom: 0px;
    margin-top: -20px;
    margin-left: 11px;
     color: #3a808e;
    
    }
   
    .socialIcons{
       
    float: left;
    width: 38px;
    margin-left: 15px;
    padding-top: 15px;
    }

    .iconFacebook {
   background-image:url(http://zoologicalsociety.dev/wp-content/uploads/2016/11/social-icons.png);
   background-position-x: left;
    background-position-y: top;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
}

.iconFacebook, .iconEmail {
    width: 38px;
    height: 38px;
    display: block;
}

.iconEmail {
    margin-top: -18px;
    margin-left: 2px;
    background-image: url(http://zoologicalsociety.dev/wp-content/uploads/2016/11/social-icons-Email.png);
}
}
