/*
 * Social Network Colours
 *
 * http://designpieces.com/2012/12/social-media-colours-hex-and-rgb/
 */
$twitter:    #00aced;
$facebook:   #3b5998;
$linkedin:   #007bb6;
$google:     #dd4b39;
$youtube:    #bb0000;
$pinterest:  #cb2027;
$tumblr:     #32506d;
$flickr:     #ff0084;
$instagram:  #517fa4;
$vimeo:      #aad450;
$foursquare: #0072b1;

/*
 * Brand Colours
 */
$light-grey:  #A8A9A8;
$medium-grey: #9E9F9E;
$dark-grey:   #5E5E5E;
$white:       #fff;
$blue:   #4986c7;
$cloud: #c59ca0;
$light-green: #e2f0d9;
$darker-green: #3C7F8C;



// General colours
$header-bg:         $light-green;
$body-bg:           $white;
$footer-widgets-bg: $medium-grey;
$footer-bg:         $dark-grey;
$link-colour:       $cloud;
$heading-colour: 		#333;

// Primary Nav
$primary-nav-bg: 			$dark-grey;
$primary-nav-text: 			$white;
$primary-nav-text-hover: 	$light-grey;

// Text colours
$footer-text: #fff;
$footer-link: $cloud;

/*
* Backgrounds
*/
.bg-light {
	background: $light-grey;
}

.bg-medium {
	background: $medium-grey;
}

.bg-dark {
	background: $dark-grey;
}

/*
 * Buttons
 */
.button {
	&.primary {
		background: $dark-grey;
		color: #fff;
	}

	&.secondary {
	 	background: $light-grey;
	 	color: $dark-grey;
	}

	&.outline {
		border: 1px solid $dark-grey;
		color: $dark-grey;
	}
}
