.button-style {
   background: $darker-green;
   border-radius: 0;
   border: none;
   color: #fff;
   text-transform: uppercase;

   &:hover {
      background: darken($darker-green, 5%);
   }
}

.fl-builder .fl-builder-content {
   a.fl-button,
   a.fl-button:visited {
      @extend .button-style;

      span {
         color: #fff;
         text-transform: uppercase;
      }
   }
}
.fl-page-content {
   button,
   input[type=button],
   input[type=submit] {
      @extend .button-style;
      color: #fff;
      text-transform: uppercase;
   }
}

.fl-sidebar {
   .button-style {
      padding: 6px 12px;

      &:hover {
         color: #fff;
         text-decoration: none;
      }
   }
}

input#gform_submit_button_14.gform_button.button {
   @extend .button-style;
   padding: 12px 24px;
}

.gform_wrapper input[type=email],
.gform_wrapper input[type=number],
.gform_wrapper input[type=password],
.gform_wrapper input[type=tel],
.gform_wrapper input[type=text],
.gform_wrapper input[type=url] {
   padding: 5px 8px !important;
   border-radius: 0 !important;
}
