.overlay {
   .fl-heading span {
      background: rgba(255,255,255, 0.65);
      padding: 10px 15px;
   }


   .fl-rich-text span {
      background: rgba(255,255,255, 0.8);
      padding: 10px 15px;
   }
}

.thirds {
   .fl-callout-photo-left .fl-callout-photo {
      width: 33.33333% !important;
   }

   .fl-callout-photo-left .fl-callout-content {
      width: 66.66666% !important;
   }

}

.shadow {
   .fl-heading {
      text-shadow: rgba(0,0,0,0.5) 0px 2px 5px;
   }
}

.footer-sponsors-wrap {
   max-width: 1200px;
   margin: 0 auto;
   padding: 20px;
   border-top: 1px solid #e6e6e6;

   @include clearfix;

   h6 {
      color:  #7d7b7b;
   }
}

.footer-sponsors {

   @include breakpoint($mama-bear-and-up) {
      &.principal {
         @include span(1 of 5);
      }

      &.naming {
         @include span(1 of 5);
      }

      &.major {
         @include span(3 of 5);
         @include last;

         .cr3_sponsorwrapper .fours-column {
            width: auto !important;
         }
      }
   }
}

.fl-page-footer {
   border-top: none !important;
}

.fl-page-nav-wrap {
   border-top: none !important;
   margin-top: -20px;
}
