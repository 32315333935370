﻿.calIcon {
    background-image:url(http://zoologicalsociety.dev/wp-content/uploads/2016/11/calendarIcon.jpg);
    width: 200px;
    height: 185px;
}

.calIcon, .calExtras {
    text-align: center;
}

.calIconMonth {
    height: 58px;
    color: white;
    padding-top: 00px;
    font:bolder;
}

.calSpanDay {
    width: 100%;
    display: block;
    font:bolder;
    font-size:140%;
    }

.calSpanMonth {
    font-size: 180%;
    font:bolder;
    margin-top:-5px;
}

.calSpanMonth, .calSpanDay {
    width: 100%;
    display: block;
    }
.calIconDate {
    padding-top: 0;
    font-size: 750%;
    position: relative;
    float: none;
    margin-top: -20px;
    color: #2C3B60;
    font:bolder;
}
