/*
Theme Name: Adam Chamber Music Festival (Beaver Builder Child Theme)
Theme URI: http://www.wpbeaverbuilder.com
Version: 1.0
Description: An example child theme that can be used as a starting point for custom development.
Author: Avoca Web Design
Author URI: http://avoca.design
template: bb-theme
*/

//Import Bower Components
@import "susy/sass/susy";
@import "compass-breakpoint/stylesheets/breakpoint";
@import "modular-scale/stylesheets/modular-scale";

// Import setting partials and selected utilities
@import "settings/load";
@import "utilities/entypo";
@import "utilities/clearfix";

// Icon font path
$en-path: "fonts/entypo/entypo-plus";

@include en-font;

// Import Styling partials
@import "colours";
@import "buttons";
@import "icon-fonts";
@import "page-builder";
@import "sidebar";
@import "footer";
@import "slogan";
@import "calendar";


/* Add your custom styles here... */

a {
   &:hover {
      color: darken($cloud, 10%);
   }
}
