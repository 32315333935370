.fl-sidebar {
   .fl-widget ul li {
      background: $cloud;
      color: #fff;

      a {
         display: block;
         width: 100%;
         padding: 5px 12px;
         background: $cloud;
         color: #fff;
         text-transform: uppercase;
         text-decoration: none;

         &:hover {
            background: darken($cloud, 10%);
         }
      }

      &.current-menu-item a {
         background: darken($cloud, 10%);
      }


   }

   .fl-widget.widget_recent_entries ul li {
      background: transparent;

      a {
         background: transparent;
         color: #7f7f7f;
         padding: 0;

         &:hover {
            color: $cloud;
         }
      }
   }

}
