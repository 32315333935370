﻿.copyright {
    display: block;
    float: right;
    margin-top: -40px;
    margin-right: 25px;
    max-width: 50%;
    text-align: right;
    font-size: 100%;

    .copyright-text,
    .avoca-design {
        display: inline-block;
        font-size:12px;
        color:#428bca;
    }

    .copyright-text {

    }

    .avoca-design {

    }
}

// change column width for footer
@media (min-width: 992px) {
    .fl-page-footer .col-md-6 {

        &.text-left {
            width: 30%;

            img {
                max-width: 150px;
            }
        }

        &.text-right {
            width: 70%;

            .fl-page-footer-nav {
                margin-top: 20px;

                 a {
                    padding-right: 0;
                 }
            }
        } 
    }
}
