@charset "UTF-8";
/*
Theme Name: Adam Chamber Music Festival (Beaver Builder Child Theme)
Theme URI: http://www.wpbeaverbuilder.com
Version: 1.0
Description: An example child theme that can be used as a starting point for custom development.
Author: Avoca Web Design
Author URI: http://avoca.design
template: bb-theme
*/
/*
* 	Main Icon mixin
*
* 	Based on http://jaydenseric.com/blog/fun-with-sass-and-font-icons 
*
*	e.g. 
*	@include icon('phone', 'before', true, $large, $top:-3px, $margin:5px);
* 	@include icon('phone', 'before', true, $large, $top:-3px, $margin:5px) {
*		color: black;
* 	}
*/
/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.cf:before,
.cf:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.cf:after {
  clear: both; }

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf {
  *zoom: 1; }

/* ## Float Clearing
--------------------------------------------- */
.author-box:before,
.clearfix:before,
.entry:before,
.entry-content:before,
.footer-widgets:before,
.nav-primary:before,
.nav-secondary:before,
.pagination:before,
.site-container:before,
.site-footer:before,
.site-header:before,
.site-inner:before,
.wrap:before {
  content: " ";
  display: table; }

.author-box:after,
.clearfix:after,
.entry:after,
.entry-content:after,
.footer-widgets:after,
.nav-primary:after,
.nav-secondary:after,
.pagination:after,
.site-container:after,
.site-footer:after,
.site-header:after,
.site-inner:after,
.wrap:after {
  clear: both;
  content: " ";
  display: table; }

@font-face {
  font-family: "entypo";
  src: url("fonts/entypo/entypo-plus.eot");
  src: url("fonts/entypo/entypo-plus.eot?#iefix") format("embedded-opentype"), url("fonts/entypo/entypo-plus.woff") format("woff"), url("fonts/entypo/entypo-plus.ttf") format("truetype"), url("fonts/entypo/entypo-plus.svg#entypo") format("svg");
  font-weight: normal;
  font-style: normal;
  speak: none; }

/*
 * Social Network Colours
 *
 * http://designpieces.com/2012/12/social-media-colours-hex-and-rgb/
 */
/*
 * Brand Colours
 */
/*
* Backgrounds
*/
.bg-light {
  background: #A8A9A8; }

.bg-medium {
  background: #9E9F9E; }

.bg-dark {
  background: #5E5E5E; }

/*
 * Buttons
 */
.button.primary {
  background: #5E5E5E;
  color: #fff; }

.button.secondary {
  background: #A8A9A8;
  color: #5E5E5E; }

.button.outline {
  border: 1px solid #5E5E5E;
  color: #5E5E5E; }

.button-style, .fl-builder .fl-builder-content a.fl-button,
.fl-builder .fl-builder-content a.fl-button:visited, .fl-page-content button,
.fl-page-content input[type=button],
.fl-page-content input[type=submit], input#gform_submit_button_14.gform_button.button {
  background: #3C7F8C;
  border-radius: 0;
  border: none;
  color: #fff;
  text-transform: uppercase; }
  .button-style:hover, .fl-builder .fl-builder-content a.fl-button:hover, .fl-page-content button:hover,
  .fl-page-content input[type=button]:hover,
  .fl-page-content input[type=submit]:hover, input#gform_submit_button_14.gform_button.button:hover {
    background: #346f7a; }

.fl-builder .fl-builder-content a.fl-button span,
.fl-builder .fl-builder-content a.fl-button:visited span {
  color: #fff;
  text-transform: uppercase; }

.fl-page-content button,
.fl-page-content input[type=button],
.fl-page-content input[type=submit] {
  color: #fff;
  text-transform: uppercase; }

.fl-sidebar .button-style, .fl-sidebar .fl-builder .fl-builder-content a.fl-button, .fl-builder .fl-builder-content .fl-sidebar a.fl-button, .fl-sidebar .fl-page-content button, .fl-page-content .fl-sidebar button, .fl-sidebar
.fl-page-content input[type=button],
.fl-page-content .fl-sidebar input[type=button], .fl-sidebar
.fl-page-content input[type=submit],
.fl-page-content .fl-sidebar input[type=submit], .fl-sidebar input#gform_submit_button_14.gform_button.button {
  padding: 6px 12px; }
  .fl-sidebar .button-style:hover, .fl-sidebar .fl-builder .fl-builder-content a.fl-button:hover, .fl-builder .fl-builder-content .fl-sidebar a.fl-button:hover, .fl-sidebar .fl-page-content button:hover, .fl-page-content .fl-sidebar button:hover, .fl-sidebar
  .fl-page-content input[type=button]:hover,
  .fl-page-content .fl-sidebar input[type=button]:hover, .fl-sidebar
  .fl-page-content input[type=submit]:hover,
  .fl-page-content .fl-sidebar input[type=submit]:hover, .fl-sidebar input#gform_submit_button_14.gform_button.button:hover {
    color: #fff;
    text-decoration: none; }

input#gform_submit_button_14.gform_button.button {
  padding: 12px 24px; }

.gform_wrapper input[type=email],
.gform_wrapper input[type=number],
.gform_wrapper input[type=password],
.gform_wrapper input[type=tel],
.gform_wrapper input[type=text],
.gform_wrapper input[type=url] {
  padding: 5px 8px !important;
  border-radius: 0 !important; }

/**
 * Icon Fonts
 */
.phone-number {
  margin-bottom: 0.80901rem; }
  .phone-number:before {
    content: "";
    position: relative;
    display: inline-block;
    width: auto;
    text-decoration: none;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    speak: none;
    text-transform: none;
    line-height: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-family: "entypo";
    font-size: 16px;
    font-size: 1.3em;
    margin-right: 6px;
    position: relative;
    top: 1px;
    margin-left: 2px; }

.mobile-number {
  margin-bottom: 0.80901rem; }
  .mobile-number:before {
    content: "";
    position: relative;
    display: inline-block;
    width: auto;
    text-decoration: none;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    speak: none;
    text-transform: none;
    line-height: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-family: "entypo";
    font-size: 16px;
    font-size: 1.3em;
    margin-right: 10px;
    position: relative;
    top: 1px;
    margin-left: 3px; }

.email {
  margin-bottom: 0.80901rem; }
  .email:before {
    content: "";
    position: relative;
    display: inline-block;
    width: auto;
    text-decoration: none;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    speak: none;
    text-transform: none;
    line-height: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-family: "entypo";
    font-size: 16px;
    font-size: 1.3em;
    margin-right: 10px;
    position: relative;
    top: 1px;
    margin-left: 3px; }

.address-full {
  margin-bottom: 0.80901rem; }
  .address-full:before {
    content: "";
    position: relative;
    display: inline-block;
    width: auto;
    text-decoration: none;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    speak: none;
    text-transform: none;
    line-height: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-family: "entypo";
    font-size: 16px;
    font-size: 1.3em;
    margin-right: 5px;
    position: relative;
    top: 0;
    float: left;
    display: block; }
  .address-full .street-address-wrap,
  .address-full .city-postcode {
    padding-left: 25px; }

.facebook-link:before {
  content: "";
  position: relative;
  display: inline-block;
  width: auto;
  text-decoration: none;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  speak: none;
  text-transform: none;
  line-height: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-family: "entypo";
  font-size: 16px;
  font-size: 1.8em;
  margin-right: 7px;
  position: relative;
  top: 3px;
  color: #3b5998; }

.overlay .fl-heading span {
  background: rgba(255, 255, 255, 0.65);
  padding: 10px 15px; }

.overlay .fl-rich-text span {
  background: rgba(255, 255, 255, 0.8);
  padding: 10px 15px; }

.thirds .fl-callout-photo-left .fl-callout-photo {
  width: 33.33333% !important; }

.thirds .fl-callout-photo-left .fl-callout-content {
  width: 66.66666% !important; }

.shadow .fl-heading {
  text-shadow: rgba(0, 0, 0, 0.5) 0px 2px 5px; }

.footer-sponsors-wrap {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  border-top: 1px solid #e6e6e6;
  *zoom: 1; }
  .footer-sponsors-wrap:before, .footer-sponsors-wrap:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */ }
  .footer-sponsors-wrap:after {
    clear: both; }
  .footer-sponsors-wrap h6 {
    color: #7d7b7b; }

@media (min-width: 768px) {
  .footer-sponsors.principal {
    width: 15.78947%;
    float: left;
    margin-right: 5.26316%; }
  .footer-sponsors.naming {
    width: 15.78947%;
    float: left;
    margin-right: 5.26316%; }
  .footer-sponsors.major {
    width: 57.89474%;
    float: left;
    margin-right: 5.26316%;
    float: right;
    margin-right: 0; }
    .footer-sponsors.major .cr3_sponsorwrapper .fours-column {
      width: auto !important; } }

.fl-page-footer {
  border-top: none !important; }

.fl-page-nav-wrap {
  border-top: none !important;
  margin-top: -20px; }

.fl-sidebar .fl-widget ul li {
  background: #c59ca0;
  color: #fff; }
  .fl-sidebar .fl-widget ul li a {
    display: block;
    width: 100%;
    padding: 5px 12px;
    background: #c59ca0;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none; }
    .fl-sidebar .fl-widget ul li a:hover {
      background: #b27c81; }
  .fl-sidebar .fl-widget ul li.current-menu-item a {
    background: #b27c81; }

.fl-sidebar .fl-widget.widget_recent_entries ul li {
  background: transparent; }
  .fl-sidebar .fl-widget.widget_recent_entries ul li a {
    background: transparent;
    color: #7f7f7f;
    padding: 0; }
    .fl-sidebar .fl-widget.widget_recent_entries ul li a:hover {
      color: #c59ca0; }

.copyright {
  display: block;
  float: right;
  margin-top: -40px;
  margin-right: 25px;
  max-width: 50%;
  text-align: right;
  font-size: 100%; }
  .copyright .copyright-text,
  .copyright .avoca-design {
    display: inline-block;
    font-size: 12px;
    color: #428bca; }

@media (min-width: 992px) {
  .fl-page-footer .col-md-6.text-left {
    width: 30%; }
    .fl-page-footer .col-md-6.text-left img {
      max-width: 150px; }
  .fl-page-footer .col-md-6.text-right {
    width: 70%; }
    .fl-page-footer .col-md-6.text-right .fl-page-footer-nav {
      margin-top: 20px; }
      .fl-page-footer .col-md-6.text-right .fl-page-footer-nav a {
        padding-right: 0; } }

.sloganSocial {
  float: right; }
  .sloganSocial .slogan {
    float: left;
    font-family: museo, serif;
    color: #3a808e;
    text-align: center; }
  .sloganSocial .sloganTo {
    font-weight: 700;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: -60px;
    font-size: 100%;
    color: #3a808e; }
  .sloganSocial .sloganInspire {
    font-weight: 700;
    font-size: 202%;
    margin-top: -15px;
    color: #3a808e; }
  .sloganSocial .sloganExcite {
    font-weight: 500;
    font-size: 177%;
    margin-left: 25px;
    margin-top: -25px;
    margin-bottom: 0px;
    color: #3a808e; }
  .sloganSocial .sloganEducate {
    font-weight: 300;
    font-size: 237%;
    margin-bottom: 0px;
    margin-top: -20px;
    margin-left: 11px;
    color: #3a808e; }
  .sloganSocial .socialIcons {
    float: left;
    width: 38px;
    margin-left: 15px;
    padding-top: 15px; }
  .sloganSocial .iconFacebook {
    background-image: url(http://zoologicalsociety.dev/wp-content/uploads/2016/11/social-icons.png);
    background-position-x: left;
    background-position-y: top;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial; }
  .sloganSocial .iconFacebook, .sloganSocial .iconEmail {
    width: 38px;
    height: 38px;
    display: block; }
  .sloganSocial .iconEmail {
    margin-top: -18px;
    margin-left: 2px;
    background-image: url(http://zoologicalsociety.dev/wp-content/uploads/2016/11/social-icons-Email.png); }

.calIcon {
  background-image: url(http://zoologicalsociety.dev/wp-content/uploads/2016/11/calendarIcon.jpg);
  width: 200px;
  height: 185px; }

.calIcon, .calExtras {
  text-align: center; }

.calIconMonth {
  height: 58px;
  color: white;
  padding-top: 00px;
  font: bolder; }

.calSpanDay {
  width: 100%;
  display: block;
  font: bolder;
  font-size: 140%; }

.calSpanMonth {
  font-size: 180%;
  font: bolder;
  margin-top: -5px; }

.calSpanMonth, .calSpanDay {
  width: 100%;
  display: block; }

.calIconDate {
  padding-top: 0;
  font-size: 750%;
  position: relative;
  float: none;
  margin-top: -20px;
  color: #2C3B60;
  font: bolder; }

/* Add your custom styles here... */
a:hover {
  color: #b27c81; }
